@import "../../../../styles/variables.css";

@keyframes pulse {
  0% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(1.2);
  }
}

.tic-tac-toe > .board {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 0.75rem;
  background: var(--color-black);

  &:not(.winner-found) {
    & .row .square:hover {
      background: var(--color-slate);
      color: var(--color-gray);
    }
  }
  & .row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0.75rem;
    background: var(--color-black);

    & .square {
      font-size: 9rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: var(--color-gray);

      &.winner {
        & .player {
          z-index: 2;
          color: var(--color-green);
          animation: pulse 1.5s infinite ease-in-out;
        }
      }
    }
  }
}

@media screen and (min-width: 960px) {
  .tic-tac-toe .board {
    grid-gap: 1rem;

    & .row {
      grid-gap: 1rem;

      & .square {
        font-size: 12rem;
      }
    }
  }
}

@media screen and (max-height: 600px) {
  .tic-tac-toe .board {
    grid-gap: 0.5rem;

    & .row {
      grid-gap: 0.5rem;

      & .square {
        font-size: 2rem;
      }
    }
  }
}
